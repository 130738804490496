import React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import DropZone from "./DropZone";
import axios from "axios";
import Alert from "@mui/material/Alert";

type ParserAPIResponse = {
  fileName: string;
  statement: string[][];
};

const apiUrlBase = "https://ynab-be.rad-iq.com/";

const escapeCSVValue = (value: string) => `"${value.replace(/"/g, '""')}"`;

function App() {
  const parserValues = [
    { label: "swed", value: "swed" },
    { label: "dnb", value: "dnb" },
    { label: "revolut", value: "revolut" },
  ];

  const [parser, setParser] = React.useState(parserValues[0].value);
  const [errorMessage, setErrorMessage] = React.useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setParser(event.target.value);
  };

  const handleFIle = async (content: string) => {
    try {
      setErrorMessage("");
      const result = await axios.post<ParserAPIResponse>(
        `${apiUrlBase}parser/${parser}`,
        content,
        {
          headers: { "Content-Type": "text/plain" },
        }
      );
      const statements = result.data.statement.map((fields) =>
        fields.map(escapeCSVValue)
      );
      const data = statements.map((item) => item.join(",")).join("\n");
      const element = document.createElement("a");
      const file = new Blob([data], { type: "text/plain" });
      element.href = URL.createObjectURL(file);
      element.download = `${new Date()
        .toISOString()
        .slice(0, 10)}-${parser}-ynab-statement.csv`;
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error.response);
        setErrorMessage(error.response?.data?.errorMessage || error.message);
      } else {
        setErrorMessage("Unknown error");
      }
      console.error(error);
    }
  };

  return (
    <Box padding={5}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Parser</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={parser}
          label="Parser"
          onChange={handleChange}
        >
          {parserValues.map((value) => {
            return (
              <MenuItem key={value.value} value={value.value}>
                {value.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <DropZone onTextFileRead={handleFIle} />
      <Box paddingTop={3}>
        {errorMessage !== "" && <Alert severity="error">{errorMessage}</Alert>}
      </Box>
    </Box>
  );
}

export default App;
