import { useDropzone } from "react-dropzone";

type DropZoneProps = {
  onTextFileRead: (content: string) => void;
};

function DropZone(props: DropZoneProps) {
  const onDrop = (acceptedFiles: any[]) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onabort = () => console.log("file reading was aborted");
    reader.onerror = () => console.log("file reading has failed");
    reader.onload = () => {
      // Do whatever you want with the file contents
      const content = reader.result;
      if (typeof content === "string") {
        props.onTextFileRead(content);
      } else {
        console.error("File as text is not string");
      }
    };
    reader.readAsText(file);
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div
      style={{
        backgroundColor: "#DDD",
        textAlign: "center",
        padding: 50,
        marginTop: 20,
      }}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drop the files here ...</p>
      ) : (
        <p>Drag 'n' drop some files here, or click to select files</p>
      )}
    </div>
  );
}

export default DropZone;
